import localFont from 'next/font/local'

const Zermatt = localFont({
  src: [
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_black_italic-webfont.woff2',
      weight: '800',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_black-webfont.woff2',
      weight: '800',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_bold_italic-webfont.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_bold-webfont.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_fine_italic-webfont.woff2',
      weight: '200',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_fine-webfont.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_light_italic-webfont.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_light-webfont.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_medium_italic-webfont.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_medium-webfont.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_italic-webfont.woff2',
      weight: 'normal',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/zermatt/muhittin_gunes_-_zermatt_regular-webfont.woff2',
      weight: 'normal',
      style: 'normal',
    },
  ],
  variable: '--font-Zermatt',
  display: 'swap',
  fallback: ['sans-serif'],
})

const Poppins = localFont({
  src: [
    {
      path: '../../assets/fonts/poppins/poppins-black-webfont.woff2',
      weight: '800',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-blackitalic-webfont.woff2',
      weight: '800',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-bold-webfont.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-bolditalic-webfont.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-light-webfont.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-lightitalic-webfont.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-regular-webfont.woff2',
      weight: 'normal',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-italic-webfont.woff2',
      weight: 'normal',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-medium-webfont.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-mediumitalic-webfont.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-semibold-webfont.woff2',
      weight: '550',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-semibolditalic-webfont.woff2',
      weight: '550',
      style: 'italic',
    },
    {
      path: '../../assets/fonts/poppins/poppins-thin-webfont.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/poppins/poppins-thinitalic-webfont.woff2',
      weight: '200',
      style: 'italic',
    },
  ],
  variable: '--font-Poppins',
  display: 'swap',
  fallback: ['sans-serif'],
})

const BlackDelights = localFont({
  src: [
    {
      path: '../../assets/fonts/blackDelights/BlackDelights.woff2',
      weight: 'normal',
      style: 'normal',
    },
  ],
  variable: '--font-BlackDelights',
  display: 'swap',
  fallback: ['serif'],
})

const fonts = { Zermatt, Poppins, BlackDelights }

export default fonts
