import localFont from 'next/font/local'

const HelveticaNeue = localFont({
  src: [
    {
      path: '../../assets/fonts/helveticaneue/helveticaneue-thin-webfont.woff2',
      weight: '100',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/helveticaneue/helveticaneue-ultralight-webfont.woff2',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/helveticaneue/helveticaneue-light-webfont.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/helveticaneue/helveticaneue-roman-webfont.woff2',
      weight: 'normal',
      style: 'normal',
    },
  ],
  variable: '--font-HelveticaNeue',
  display: 'swap',
  fallback: ['Helvetica Neue'],
})

const Georgia = localFont({
  src: [
    {
      path: '../../assets/fonts/georgia/Georgia.woff2',
      weight: 'normal',
    },
  ],
  variable: '--font-Georgia',
  display: 'swap',
  fallback: ['sans-serif'],
})

const Floreal = localFont({
  src: [
    {
      path: '../../assets/fonts/floreal/Floreal.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/floreal/Floreal-Italic.woff',
      weight: '400',
      style: 'italic',
    },
  ],
  variable: '--font-Floreal',
  display: 'swap',
  fallback: ['serif'],
})

const fonts = { HelveticaNeue, Georgia, Floreal }

export default fonts
