import { css, Global } from '@emotion/react'
import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'
import { isSiteAmels, isSiteYachting } from '../themes/utils'

const GlobalStyles = () => (
  <Global
    styles={(theme) => [
      css`
        body {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          ${theme.text.body(theme)}
        }
        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        a {
          text-decoration: none;

          :hover {
            text-decoration: underline;
          }
        }

        figure,
        ul {
          margin: 0;
          padding: 0;
        }

        svg {
          width: auto;
          height: auto;
        }

        p {
          margin: ${theme.spacing.x2}px 0;
        }

        * {
          outline-color: currentColor;
        }

        // Fix for menu alignment in Firefox (override for sanatize.css)
        nav li::before {
          content: none;
        }
      `,
      isSiteAmels(theme) &&
        css`
          body {
            background: ${theme.colors.amels.body};
          }

          a {
            color: ${theme.colors.amels.deepBayAqua};
          }

          ::placeholder {
            color: ${theme.colors.amels.deepBayAqua60};
          }
        `,
      isSiteYachting(theme) &&
        css`
          a {
            color: ${theme.colors.yachting.darkBlue};
          }
        `,
      // TODO links.globalStyles,
      // TODO headings.globalStyles,
    ]}
  />
)

export default GlobalStyles
