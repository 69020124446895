import {
  Orbitron as GoogleOrbitron,
  Rubik as GoogleRubik,
} from 'next/font/google'

const Orbitron = GoogleOrbitron({
  display: 'swap',
  subsets: ['latin'],
  fallback: ['serif'],
})

const Rubik = GoogleRubik({
  display: 'swap',
  subsets: ['latin'],
  fallback: ['serif'],
})

const fonts = { Orbitron, Rubik }

export default fonts
